<template>

  <div class="m-0 p-0">
    <b-modal ref="select-responsible-person-for-fabric-request" id="select-user-modal" hide-header hide-header-close size="md" scrollable>
      <select-sample-user-modal
          :model.sync="selectedResponsiblePersonForFabricRequest"
          :users="usersList"
          @onConfirm="onResponsiblePersonSelected"
      ></select-sample-user-modal>

      <template #modal-footer>
        <div class="w-100">
          <div class="d-flex justify-content-center mt-3">
            <button
                type="button"
                @click="onResponsiblePersonSelected"
                :disabled="selectedResponsiblePersonForFabricRequest == null"
                class="btn btn-outline-primary font-weight-bold cursor-pointer ml-4 text-uppercase rounded-full ">
              <span class="font-weight-boldest" style="font-weight: bolder;">{{ $t('general.save') }}</span>
            </button>
          </div>
        </div>
      </template>
    </b-modal>

    <template v-if="Number(requestType) === 1">
      <div v-if="!selectedResponsiblePersonForFabricRequest && isUserGranted('Staff', ['brandChief'], false)" class="d-flex justify-content-center my-5">
        <button-with-icon
            :icon-name="null"
            :text="$t('sample.create_request_fabric').toUpperCase()"
            class="text-primary mr-3"
            size="md"
            @onClick="confirmRequestFabric"
        ></button-with-icon>
      </div>
      <template v-if="selectedResponsiblePersonForFabricRequest !== null">
        <div class="d-flex justify-content-center items-center mb-4">
                    <span v-html="getIconByKey('icons.sample_instruction.product_responsible', {
                      class: 'w-30px h-30px object-fill d-line-block mr-1'
                    })"></span>
          <p class="mb-0">
            <span class="text-xl font-weight-bolder bottom-border-1px-navy">{{
                $t('sample.product_responsible').toUpperCase()
              }} : </span>

            <span
                class="text-xl font-weight-boldest text-primary">{{ (convertArrayToObjectByKey(usersList, 'id')[selectedResponsiblePersonForFabricRequest]) ? (convertArrayToObjectByKey(usersList, 'id')[selectedResponsiblePersonForFabricRequest]).name : '-'
              }}</span>
            <span v-if="selectedResponsiblePersonForFabricRequest !== null && !selected_brand_manager_id && isUserGranted('Staff', ['brandChief'], false)" class="d-inline-block ml-3">
              <button-with-icon
                  :text="$t('sample.send_to_responsible_person')"
                  icon-name="icons.sample_instruction.product_responsible"
                  size="sm"
                  @onClick="createResponsiblePersonForFabricRequest"
              ></button-with-icon>
            </span>

            <span v-if="selectedResponsiblePersonForFabricRequest !== null && selected_brand_manager_id && isUserGranted('Staff', ['brandChief'], false)" class="d-inline-block ml-3">
              <button-with-icon
                  :text="$t('sample.change_to_responsible_person')"
                  icon-name="icons.sample_instruction.product_responsible"
                  size="sm"
                  @onClick="confirmRequestFabric"
              ></button-with-icon>
            </span>
          </p>
        </div>
        <template v-if="selectedResponsiblePersonForFabricRequest && selected_brand_manager_id">
          <div class="d-flex justify-content-center items-center">
                  <span v-html="getIconByKey('icons.sample_instruction.question', {
                      class: 'w-30px h-30px object-fill d-line-block mr-1'
                    })"></span>
            <p class="mb-0 font-weight-bolder text-navy-blue text-xl">
              {{ $t('sample.how_to_make_fabric_question') }}
            </p>
          </div>
          <div v-if="fabricSourcingType != null" class="d-flex justify-content-center items-center mt-1 mb-2">
                    <span v-html="getIconByKey('icons.sample_instruction.fabric_supply_management', {
                      class: 'w-30px h-30px object-fill d-line-block mr-1'
                    })"></span>
            <p class="mb-0">
          <span class="text-xl font-weight-bolder bottom-border-1px-navy">{{
              $t('sample.fabric_supply_method').toUpperCase()
            }}</span> :
              <span
                  class="text-xl font-weight-boldest text-primary">{{
                  $t(fabricSourcingType === 1 ? 'sample.outsourcing' : 'sample.buy_ready_made')
                }}</span>
            </p>
          </div>
          <div v-if="fabricProcessingType && fabricSourcingType === 1"
               class="d-flex justify-content-center items-center mt-3 mb-1">
                    <span v-html="getIconByKey('icons.sample_instruction.product_process', {
                      class: 'w-30px h-30px object-fill d-line-block mr-1'
                    })"></span>
            <p class="mb-0">
          <span class="text-xl font-weight-bolder bottom-border-1px-navy">{{
              $t('sample.outsourcing_method').toUpperCase()
            }}</span> :
              <span class="text-xl font-weight-boldest text-primary">
                      {{
                  $t(fabricProcessingType === 1 ? 'sample.yarn_knitting_paint' : fabricProcessingType === 2 ? 'sample.yarn_knitting_print' : fabricProcessingType === 3 ? 'sample.raw_fabric_paint' : 'sample.raw_fabric_print')
                }}
            </span>
            </p>
          </div>

          <div v-if="fabricSourcingType != null"
               class="d-flex w-100 justify-content-center items-baseline mt-5 mb-2 text-center">
            <div class="text-xl font-weight-boldest text-primary w-200px">
              <custom-multi-select
                  :item-per-row="1"
                  :max="1"
                  :model.sync="fabricSourcingType"
                  :not-list="true"
                  :options="sourcingTypeOptions"
                  :placeholder="$t('general.please_select_option')"
                  :required="false"
                  :title="null"
                  name="sourcingTypeOptions"
                  width="300px"
                  @onSelect="getRequests()"
              ></custom-multi-select>
            </div>
            <div v-if="fabricProcessingType !== null && +fabricSourcingType === 1"
                 class="text-xl font-weight-boldest text-primary w-200px pb-2">

              <custom-multi-select
                  :item-per-row="1"
                  :max="1"
                  :model.sync="fabricProcessingType"
                  :not-list="true"
                  :options="fabricProcessingTypes"
                  :placeholder="$t('general.please_select_option')"
                  :required="false"
                  :title="null"
                  name="fabricProcessingType"
                  width="300px"
                  @onSelect="getRequests"
              ></custom-multi-select>
            </div>

            <div
                v-if="[2, 4].includes(+fabricProcessingType) && selectedPrintTypeId && +fabricSourcingType === 1"
                class="text-xl font-weight-boldest text-primary w-200px pb-2">
              <custom-multi-select
                  :item-per-row="1"
                  :max="1"
                  :model.sync="selectedPrintTypeId"
                  :not-list="true"
                  :options="pressTypeOptions"
                  :placeholder="$t('general.please_select_option')"
                  :required="false"
                  :title="null"
                  name="sourcingTypeOptions"
                  width="300px"
              ></custom-multi-select>
            </div>

          </div>
          <template v-if="fabricSourcingType === null">
            <div class="d-flex justify-content-center items-center">
                  <span v-html="getIconByKey('icons.sample_instruction.question', {
                      class: 'w-30px h-30px object-fill d-line-block mr-1'
                    })"></span>
              <p class="mb-0 font-weight-bolder text-navy-blue text-xl">
                {{ $t('sample.how_to_make_fabric_question') }}
              </p>
            </div>
            <div class="d-flex justify-content-center mt-5">
              <button-with-icon
                  :active="true"
                  :disabled="fabricSourcingType !== 1"
                  :icon-name="null"
                  :text="$t('sample.outsourcing')"
                  class="text-primary font-weight-boldest w-250px mx-2"
                  size="lg"
                  @onClick="fabricSourcingType = 1"
              ></button-with-icon>
              <button-with-icon
                  :active="true"
                  :disabled="fabricSourcingType !== 2"
                  :icon-name="null"
                  :text="$t('sample.buy_ready_made')"
                  class="text-primary font-weight-boldest w-250px mx-2"
                  size="lg"
                  @onClick="fabricSourcingType = 2"
              ></button-with-icon>
            </div>
          </template>

          <div v-if="+fabricSourcingType === 1 && fabricProcessingType === null" class="mt-5">
            <div class="w-100 row d-flex justify-content-center my-2">
              <div v-for="(fbType, index) in fabricProcessingTypes"
                   class="col-xl-3 col-lg-3 col-md-3 col-sm-6 d-flex justify-content-center">
                <button-with-icon
                    :active="true"
                    :disabled="+fabricProcessingType !== +index"
                    :icon-name="null"
                    :text="fbType"
                    class="text-primary font-weight-boldest mx-2 w-250px"
                    size="md"
                    @onClick="fabricProcessingType = +index"
                ></button-with-icon>
              </div>
            </div>
          </div>

          <div v-if="+fabricSourcingType === 1 && orderFabricId != null"
               class="w-100 row mx-0 px-10 mb-5 items-baseline justify-content-center">
            <div v-for="(sourcingType, index) in outSourcingTypes"
                 v-if="sourcingType.value.includes(+fabricProcessingType)">
        <span
            :class="{'bottom-border-2px-primary': selectedSubSourcingType === +sourcingType.id}"
            class="text-lg font-weight-bold px-3 pb-1 cursor-pointer"
            @click="changeSelectedSubSource(sourcingType)"
        >{{ $t(index).toUpperCase() }}</span>
            </div>
            <template
                v-if="fabricProcessingType !== 0 && +fabricSourcingType === 1 && orderFabricId != null">
              <custom-multi-select
                  :item-per-row="1"
                  :max="1"
                  :model.sync="additionDetailModel[0].fabric_addition_id"
                  :name="`fabric_name_addition_option`"
                  :not-list="true"
                  :options="extraProcessTypes"
                  :required="false"
                  :size="'sm'"
                  :title="null"
                  class="mb-0"
                  width="200px"
                  @save="onFabricAdditionValueChange"
              ></custom-multi-select>
            </template>
          </div>
        </template>


        <template
            v-if="[2, 4].includes(+fabricProcessingType) && !selectedPrintTypeId && orderFabricId != null">
          <div class="w-100 d-flex flex-column justify-content-center items-center">
        <span
            class="d-inline-block py-2 my-5 text-xl font-weight-bold">{{
            $t('order.please_select_print_type')
          }}</span>
            <div class="d-flex">
              <button-with-icon v-for="(fabricPrintType, key) in pressTypeOptions"
                                :key="key"
                                :active="true"
                                :icon-name="null"
                                :text="fabricPrintType"
                                class="mw-150px mx-1"
                                @onClick="changeFabricPrintTypeId(+key)"
              ></button-with-icon>
            </div>
          </div>
        </template>
        <template
            v-else-if="!([2, 4].includes(+fabricProcessingType) && !selectedPrintTypeId) && orderFabricId != null">
          <template v-if="+fabricSourcingType === 1">
            <template v-if="+selectedSubSourcingType === 1">
              <OrderFabricYarnTypeTable
                  :amount-unit-options="unitOptions"
                  :currency-options="currencyOptions"
                  :currency-options-raw="currencyOptionsRaw"
                  :currency-rate-options="currencyRateOptions"
                  :current-instruction-type-id="currencyInstructionTypeId"
                  :default-model="yarnDetailModelDefault"
                  :did-change-processing-type="didChangeProcessingType"
                  :dispatch-location-options="dispatchLocation"
                  :expiration-type-options="expirationTypeOptions"
                  :fabric-processing-type="fabricProcessingType"
                  :fabric-yarn-type-options="yarnTypes"
                  :max-tolerance.sync="maxToleranceAmount"
                  :model.sync="yarnDetailModel"
                  :net-incoming-fabric-amount="netIncomingFabricAmount"
                  :order="sample"
                  :order-fabric-id.sync="orderFabricId"
                  :order-id="fabricRequestId"
                  :product-processes="productProcess"
                  :supplier-company-options="supplierCompanies"
                  @getIncomingFabricAmount="getIncomingFabricAmount"
              ></OrderFabricYarnTypeTable>
            </template>
            <template v-if="+selectedSubSourcingType === 2">
              <OrderFabricKnitTypeTable
                  :amount-unit-options="unitOptions"
                  :currency-options="currencyOptions"
                  :currency-options-raw="currencyOptionsRaw"
                  :currency-rate-options="currencyRateOptions"
                  :current-instruction-type-id="currencyInstructionTypeId"
                  :default-model="knitDetailModelDefault"
                  :did-change-processing-type="didChangeProcessingType"
                  :dispatch-location-options="dispatchLocation"
                  :expiration-type-options="expirationTypeOptions"
                  :fabric-knit-types="fabricKnitTypes"
                  :fabric-processing-type="fabricProcessingType"
                  :max-tolerance.sync="maxToleranceAmount"
                  :model.sync="knitDetailModel"
                  :order="sample"
                  :order-fabric-id.sync="orderFabricId"
                  :order-id="fabricRequestId"
                  :press-type-id="selectedPrintTypeId"
                  :press-type-tolerances="printTolerances"
                  :product-processes="productProcess"
                  :supplier-company-options="supplierCompanies"
                  :tolerances="tolerances"
                  :yarn-model="yarnDetailModel"
              ></OrderFabricKnitTypeTable>
            </template>
            <template v-if="+selectedSubSourcingType === 3">
              <OrderFabricPaintTypeTable
                  :amount-unit-options="unitOptions"
                  :currency-options="currencyOptions"
                  :currency-options-raw="currencyOptionsRaw"
                  :currency-rate-options="currencyRateOptions"
                  :current-instruction-type-id="currencyInstructionTypeId"
                  :default-model="paintDetailModelDefault"
                  :did-change-processing-type="didChangeProcessingType"
                  :dispatch-location-options="dispatchLocation"
                  :expiration-type-options="expirationTypeOptions"
                  :fabric-process-options="fabricProcessTypes"
                  :fabric-processing-type="fabricProcessingType"
                  :knitting-company-options="[]"
                  :model.sync="paintDetailModel"
                  :order="sample"
                  :order-fabric-id.sync="orderFabricId"
                  :order-id="fabricRequestId"
                  :previous-model="[1, 2].includes(+fabricProcessingType) ? knitDetailModel : rawFabricDetailModel"
                  :product-processes="productProcess"
                  :supplier-company-options="supplierCompanies"
                  :tolerances="tolerances"
                  :yarn-model="[1, 2].includes(+fabricProcessingType) ? yarnDetailModel : rawFabricDetailModel"
              ></OrderFabricPaintTypeTable>
            </template>
            <template v-if="+selectedSubSourcingType === 4">
              <OrderFabricRawFabricTypeTable
                  :amount-unit-options="unitOptions"
                  :currency-options="currencyOptions"
                  :currency-options-raw="currencyOptionsRaw"
                  :currency-rate-options="currencyRateOptions"
                  :current-instruction-type-id="currencyInstructionTypeId"
                  :default-model="rawFabricDetailModelDefault"
                  :did-change-processing-type="didChangeProcessingType"
                  :dispatch-location-options="dispatchLocation"
                  :expiration-type-options="expirationTypeOptions"
                  :fabric-knit-types="fabricKnitTypes"
                  :fabric-processing-type="fabricProcessingType"
                  :max-tolerance.sync="maxToleranceAmount"
                  :model.sync="rawFabricDetailModel"
                  :net-incoming-fabric-amount="netIncomingFabricAmount"
                  :order="sample"
                  :order-fabric-id.sync="orderFabricId"
                  :order-id="fabricRequestId"
                  :product-processes="productProcess"
                  :supplier-company-options="supplierCompanies"
                  @getIncomingFabricAmount="getIncomingFabricAmount"
              ></OrderFabricRawFabricTypeTable>
            </template>
            <template v-if="+selectedSubSourcingType === 5">
              <OrderFabricPrintTypeTable
                  :amount-unit-options="unitOptions"
                  :currency-options="currencyOptions"
                  :currency-options-raw="currencyOptionsRaw"
                  :currency-rate-options="currencyRateOptions"
                  :current-instruction-type-id="currencyInstructionTypeId"
                  :default-model="printDetailModelDefault"
                  :did-change-processing-type="didChangeProcessingType"
                  :dispatch-location-options="dispatchLocation"
                  :expiration-type-options="expirationTypeOptions"
                  :fabric-addition-options="extraProcessTypes"
                  :fabric-print-type-options="pressTypeOptions"
                  :fabric-process-options="fabricProcessTypes"
                  :fabric-processing-type="fabricProcessingType"
                  :model.sync="printDetailModel"
                  :order="sample"
                  :order-fabric-id.sync="orderFabricId"
                  :order-id="fabricRequestId"
                  :previous-model="[1, 2].includes(+fabricProcessingType) ? yarnDetailModel : rawFabricDetailModel"
                  :product-processes="productProcess"
                  :selected-print-type-id="selectedPrintTypeId"
                  :supplier-company-options="supplierCompanies"
                  :tolerances="printTolerances"
                  :yarn-model="[1, 2].includes(+fabricProcessingType) ? yarnDetailModel : rawFabricDetailModel"
              ></OrderFabricPrintTypeTable>
            </template>
            <template v-if="+selectedSubSourcingType === 6">
              <OrderFabricExtraProcessTypeTable
                  :amount-unit-options="unitOptions"
                  :currency-options="currencyOptions"
                  :currency-options-raw="currencyOptionsRaw"
                  :currency-rate-options="currencyRateOptions"
                  :current-instruction-type-id="currencyInstructionTypeId"
                  :default-model="additionDetailModelDefault"
                  :did-change-processing-type="didChangeProcessingType"
                  :dispatch-location-options="dispatchLocation"
                  :expiration-type-options="expirationTypeOptions"
                  :fabric-addition-options="extraProcessTypes"
                  :fabric-process-options="fabricProcessTypes"
                  :fabric-processing-type="fabricProcessingType"
                  :model.sync="additionDetailModel"
                  :order="sample"
                  :order-fabric-id.sync="orderFabricId"
                  :order-id="fabricRequestId"
                  :previous-model="[1,3].includes(+fabricProcessingType) ? paintDetailModel : printDetailModel"
                  :product-processes="productProcess"
                  :supplier-company-options="supplierCompanies"
                  :tolerances="tolerances"
                  :yarn-model="[1, 2].includes(+fabricProcessingType) ? yarnDetailModel : rawFabricDetailModel"
              ></OrderFabricExtraProcessTypeTable>
            </template>
          </template>
          <template v-else>
            <OrderFabricReadyMadeTypeTable
                :amount-unit-options="unitOptions"
                :currency-options="currencyOptions"
                :currency-options-raw="currencyOptionsRaw"
                :currency-rate-options="currencyRateOptions"
                :current-instruction-type-id="currencyInstructionTypeId"
                :default-model="readyMadeDetailModelDefault"
                :did-change-processing-type="didChangeProcessingType"
                :dispatch-location-options="dispatchLocation"
                :expiration-type-options="expirationTypeOptions"
                :fabric-process-options="fabricProcessTypes"
                :fabric-processing-type="fabricProcessingType"
                :fabric-yarn-types-options="yarnTypes"
                :max-tolerance.sync="maxToleranceAmount"
                :model.sync="readyMadeDetailModel"
                :net-incoming-fabric-amount="netIncomingFabricAmount"
                :order="sample"
                :order-fabric-id.sync="orderFabricId"
                :order-id="fabricRequestId"
                :product-processes="productProcess"
                :supplier-company-options="supplierCompanies"
                @getIncomingFabricAmount="getIncomingFabricAmount"
            ></OrderFabricReadyMadeTypeTable>
          </template>
        </template>
      </template>
    </template>

    <template v-if="Number(requestType) === 2">
      <div v-if="!continueAccessoryRequest" class="d-flex justify-content-center my-5">
        <button-with-icon
            :icon-name="null"
            :text="$t('sample.create_request_accessory').toUpperCase()"
            class="text-primary mr-3"
            size="lg"
            @onClick="confirmRequestAccessory"
        ></button-with-icon>
      </div>

      <template v-if="continueAccessoryRequest">
        <div class="my-5">
          <span class="text-2xl text-primary font-weight-boldest bottom-border-1px-primary">{{
              $t('sample.accessory_request_information').toUpperCase()
            }}</span>
        </div>
        <OrderAccessoryTable
            :amount-unit-options="unitOptions"
            :currency-options="currencyOptions"
            :currency-options-raw="currencyOptionsRaw"
            :currency-rate-options="currencyRateOptions"
            :current-instruction-type-id="currencyInstructionTypeId"
            :default-model="accessoryDetailModelDefault"
            :did-change-processing-type="didChangeProcessingType"
            :dispatch-location-options="dispatchLocation"
            :expiration-type-options="expirationTypeOptions"
            :model.sync="accessoryDetailModel"
            :order="sample"
            :order-fabric-id.sync="accessoryRequestId"
            :supplier-company-options="supplierCompanies"
        ></OrderAccessoryTable>

        <template v-if="isValidToSubmitAccessoryRequest">
          <div class="w-100 d-flex justify-content-center mt-4">
            <button-with-icon
                v-if="!accessoryRequestForm.isSubmitted"
                :icon-name="null"
                :text="$t('sample.save_and_create_instruction')"
                class="text-primary mr-3"
                size="lg"
                @onClick="submitAccessoryRequest"
            ></button-with-icon>
            <template v-for=" (accessoryResultItem, index) in accessoryExecuteResult">
              <button-with-icon
                  v-if="accessoryRequestForm.isSubmitted"
                  :text=" $t('sample.download_pdf_with_placeholder', {
                  index: index +1,
                  name: $t('sample.accessory')
                }) "
                  class="text-primary mr-3"
                  icon-name="icons.sample.pdf"
                  size="lg"
                  @onClick="openInNewTab(accessoryResultItem.pdf_link)"
              ></button-with-icon>
            </template>
            <button-with-icon
                v-if="accessoryRequestForm.isSubmitted"
                :text="$t('sample.send_as_email')"
                class="text-primary mr-3"
                icon-name="icons.sample.pdf"
                size="lg"
            ></button-with-icon>
            <button-with-icon
                v-if="accessoryRequestForm.isSubmitted"
                :text="$t('sample.repeat_instruction')"
                class="text-primary mr-3"
                icon-name="icons.sample_instruction.repeat_instruction"
                size="lg"
                @onClick="repeatAccessoryRequest"
            ></button-with-icon>
          </div>
        </template>
      </template>
    </template>


  </div>
</template>

<script>
import SelectSampleUserModal from "@/view/pages/samples/layout/meeting-detail/SelectSampleUserModal";
import {CREATE_ITEM, GET_ITEMS, LOADING, PATCH, SET_LOADING} from "@/core/services/store/REST.module";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import NumberInput from "@/assets/components/inputs/NumberInput";
import NumberInputWithUnit from "@/assets/components/inputs/NumberInputWithUnit";
import TextareaInput from "@/assets/components/inputs/TextareaInput";
import ReadyMadeAndPaintForm from "@/view/pages/samples/layout/request-fabric-accessory/layout/ReadMadeAndPaintForm";
import PressForm from "@/view/pages/samples/layout/request-fabric-accessory/layout/PressForm";
import KnittingAndRawFabricForm
  from "@/view/pages/samples/layout/request-fabric-accessory/layout/KnittingAndRawFabricForm";
import YarnForm from "@/view/pages/samples/layout/request-fabric-accessory/layout/YarnForm";
import AccessoryForm from "@/view/pages/samples/layout/request-fabric-accessory/layout/AccessoryForm";
import {mapGetters, mapMutations} from "vuex";
import {LARAVEL_DATE_FORMAT} from "@/core/config/constant";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import OrderFabricYarnTypeTable
  from "@/view/pages/samples/layout/request-fabric-accessory/layout/OrderFabricYarnTypeTable";
import OrderFabricKnitTypeTable
  from "@/view/pages/samples/layout/request-fabric-accessory/layout/OrderFabricKnitTypeTable";
import moment from "moment";
import OrderFabricPaintTypeTable
  from "@/view/pages/samples/layout/request-fabric-accessory/layout/OrderFabricPaintTypeTable";
import OrderFabricExtraProcessTypeTable
  from "@/view/pages/samples/layout/request-fabric-accessory/layout/OrderFabricExtraProcessTypeTable";
import OrderFabricPrintTypeTable
  from "@/view/pages/samples/layout/request-fabric-accessory/layout/OrderFabricPrintTypeTable";
import OrderFabricRawFabricTypeTable
  from "@/view/pages/samples/layout/request-fabric-accessory/layout/OrderFabricRawFabricTypeTable";
import OrderFabricReadyMadeTypeTable
  from "@/view/pages/samples/layout/request-fabric-accessory/layout/OrderFabricReadyMadeTypeTable";

import OrderAccessoryTable from "@/view/pages/samples/layout/request-fabric-accessory/layout/OrderAccessoryTable";
import moduleUser, {GENERAL_SETTING, MODULE_NAME as MODULE_USER} from "@/core/services/store/user.module";
import store from "@/core/services/store";
import _ from "lodash";

const _MODULE_USER = MODULE_USER;

export default {
  name: "RequestFabricAccessory",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }

    registerStoreModule(_MODULE_USER, moduleUser);
  },
  components: {
    AccessoryForm,
    YarnForm,
    KnittingAndRawFabricForm,
    PressForm,
    TextareaInput,
    NumberInputWithUnit,
    NumberInput,
    DatePickerInput,
    SelectSampleUserModal,
    ButtonWithIcon,
    CustomMultiSelect,
    ReadyMadeAndPaintForm,
    OrderFabricReadyMadeTypeTable,
    OrderFabricRawFabricTypeTable,
    OrderFabricPrintTypeTable,
    OrderFabricExtraProcessTypeTable,
    OrderFabricPaintTypeTable,
    OrderFabricKnitTypeTable,
    OrderFabricYarnTypeTable,
    OrderAccessoryTable
  },
  props: {
    requestType: {
      required: true,
      default: null,
    },
    sample: {
      required: true,
    },
    meeting: {
      required: true,
    }
  },
  computed: {
    ...mapGetters({
      loading: LOADING
    }),
    settings() {
      return this.$store.getters[_MODULE_USER + '/' + GENERAL_SETTING]
    },
    totalPriceForAccessory() {
      let totalPrice = 0;
      this.accessoryRequestForm.detail.forEach(value => {
        totalPrice += Number(value.price) * Number(value.amount);
      })
      return totalPrice;
    },
    totalPriceForFabric() {
      let yarnPrice = 0;
      let knittingPrice = 0;
      let paintPrice = 0;
      let pressPrice = 0;
      let rawFabricPrice = 0;

      this.fabricRequestForm.yarn.detail.forEach(value => {
        yarnPrice += Number(value.price) * Number(value.amount);
      });
      this.fabricRequestForm.knitting.detail.forEach(value => {
        knittingPrice += Number(value.price) * Number(value.amount);
      });
      this.fabricRequestForm.paint.detail.forEach(value => {
        paintPrice += Number(value.price) * Number(value.amount);
      });
      this.fabricRequestForm.press.detail.forEach(value => {
        pressPrice += Number(value.price) * Number(value.amount);
      });
      this.fabricRequestForm.rawFabric.detail.forEach(value => {
        rawFabricPrice += Number(value.price) * Number(value.amount);
      });

      let coefficient_1 = 1;
      let coefficient_2 = 1
      let multiplier = 1;
      if (Number(this.fabricProcessingType) === 2) {

      } else if (Number(this.fabricProcessingType) === 4) {

      }

      if (this.fabricProcessingType > 2) {
        if (Number(this.fabricRequestForm.press.press_type_id[0]) === 1) multiplier = 1.15;
        if (Number(this.fabricRequestForm.press.press_type_id[0]) === 2) multiplier = 1.18;
        if (Number(this.fabricRequestForm.press.press_type_id[0]) === 3) multiplier = 1.18;
        if (Number(this.fabricRequestForm.press.press_type_id[0]) === 3) multiplier = 1.35;
      }

      if (this.fabricProcessingType === 1) {
        return (yarnPrice + knittingPrice + paintPrice);
      } else if (this.fabricProcessingType === 2) {
        return (yarnPrice + knittingPrice + pressPrice);
      } else if (this.fabricProcessingType === 3) {
        return (rawFabricPrice * paintPrice);
      } else {
        return (rawFabricPrice * pressPrice);
      }
    },
    isFabricRequestFormCompleted() {
      if (this.fabricProcessingType === 1) {
        return this.isYarnValid() && this.isKnittingValid() && this.isPaintValid();
      } else if (this.fabricProcessingType === 2) {
        return this.isYarnValid() && this.isKnittingValid() && this.isPressValid();
      } else if (this.fabricProcessingType === 3) {
        return this.isRawFabricValid() && this.isPaintValid();
      } else {
        return this.isRawFabricValid() && this.isPressValid();
      }
    },
    isValidToSubmitFabricRequest() {
      if (this.fabricProcessingType === null && this.fabricSourcingType === null && this.selectedResponsiblePersonForFabricRequest !== null && !this.isCreatedResponsiblePerson) return true;
      if (this.fabricSourcingType === 1) {
        return (this.isFabricRequestFormCompleted && this.areExtraProcessesValid());
      } else {
        return this.isReadMadeValid() && this.areExtraProcessesValid();
      }
    },
    isValidToSubmitAccessoryRequest() {
      for (let i = 0; i < this.accessoryRequestForm.detail.length; i++) {
        let current = this.accessoryRequestForm.detail[i];
        if (current.company_id === null) return false;
        if (current.dispatch_location_id === null) return false;
        if (Number(current.amount) === 0) return false;
        if (Number(current.amount_unit_id) === 0) return false;
        if (Number(current.price) === 0) return false;
      }
      return true;
    },
    printTolerances() {
      let fabricRequests = this.tolerances;
      if (fabricRequests) {
        return {
          '1': fabricRequests.print_pigment_coefficient,
          '2': fabricRequests.print_reagent_coefficient,
          '3': fabricRequests.print_disperse_coefficient,
          '4': fabricRequests.print_devore_coefficient,
        }
      }
      return null;
    },
    tolerances() {
      let fabricRequests = null;

      if (this.settings) {
        let settings = _.first(this.settings);
        if (settings) {
          fabricRequests = settings.fabric_request;
        }
      }
      return fabricRequests;
    },
    currencyInstructionTypeId() {
      if (+this.fabricSourcingType === 2) return 5;
      return this.fabricProcessingType;
    },
    didChangeProcessingType() {
      if (+this.fabricSourcingType === 2 && +this.fabric_supply_type_id === 5) {
        return false;
      } else if (+this.fabricSourcingType === 1 && +this.fabricProcessingType === +this.fabric_supply_type_id) {
        return false;
      }
      return true
    },
    maxToleranceAmount() {
      let settings = _.first(this.settings);
      let fabricRequests;
      if (settings) {
        fabricRequests = settings.fabric_request;
      }

      const toleranceAmounts = this.printTolerances;

      if (+this.fabricSourcingType === 2) {
        return fabricRequests.ready_fabric_coefficient;
      } else if (+this.fabricProcessingType === 1) {
        return Number(fabricRequests.knit_coefficient) + Number(fabricRequests.paint_coefficient);
      } else if (+this.fabricProcessingType === 2) {
        return Number(toleranceAmounts[this.selectedPrintTypeId]) + Number(fabricRequests.knit_coefficient);
      } else if (+this.fabricProcessingType === 3) {
        return Number(fabricRequests.paint_coefficient);
      } else if (+this.fabricProcessingType === 4) {
        return toleranceAmounts[this.selectedPrintTypeId];
      } else {
        this.sweetAlertError(this.$t('order.error_occurred_with_fabric_wastage_rates'));
        return 0;
      }
    }
  },
  data() {
    return {
      selectedPrintTypeId: null,
      orderFabricId: null,
      netIncomingFabricAmount: 0,
      selected_brand_manager_id: null,
      sourcingTypeOptions: {
        '1': this.capitalize(this.$t('sample.outsourcing')),
        '2': this.capitalize(this.$t('sample.buy_ready_made'))
      },

      fabricSourcingType: null,
      fabricProcessingType: null,
      selectedSubSourcingType: null,

      fabricProcessingTypes: {
        '1': this.$t('sample.yarn_knitting_paint'),
        '2': this.$t('sample.yarn_knitting_print'),
        '3': this.$t('sample.raw_fabric_paint'),
        '4': this.$t('sample.raw_fabric_print'),
      },
      outSourcingTypes: {
        'sample.yarn': {
          id: 1,
          value: [1, 2]
        },
        'sample.knitting': {
          id: 2,
          value: [1, 2]
        },
        'sample.raw_fabric': {
          id: 4,
          value: [3, 4]
        },
        'sample.paint': { // paint
          id: 3,
          value: [1, 3]
        },
        'sample.press': { // print
          id: 5,
          value: [2, 4]
        },
        'sample.extra_processes': {
          id: 6,
          value: [1, 2, 3, 4]
        },
      },
      readyMadeDetailModel: [{}],
      yarnDetailModel: [{}],
      knitDetailModel: [{}],
      paintDetailModel: [{}],
      additionDetailModel: [{}],
      printDetailModel: [{}],
      rawFabricDetailModel: [{}],
      accessoryDetailModel: [{}],
      accessoryDetailModelDefault:
          {
            id: null,
            order_id: this.fabricRequestId,
            is_submitted: false,
            is_editing: false,
            supplier_company_id: null,
            dispatch_location_id: null,
            start_date: moment(),
            end_date: moment(),
            amount: null,
            amount_unit_id: null,
            exchange_rate: 1,
            currency_unit_id: null,
            expiry_id: null,
            price: null,
            description: null,
          },
      readyMadeDetailModelDefault:
          {
            id: null,
            order_id: this.fabricRequestId,
            is_submitted: false,
            is_editing: false,
            supplier_company_id: null,
            fabric_process_ids: [],
            dispatch_location_id: null,
            raw_flow_date: moment(),
            raw_end_date: moment(),
            painted_flow_date: moment(),
            painted_end_date: moment(),
            fabric_type: null,
            amount: null,
            amount_unit_id: null,
            total_amount: null,
            exchange_rate: 1,
            selectedProductProcesses: [],
            product_processes: [],
            currency_unit_id: null,
            expiry_id: null,
            price: null,
            description: null,
            color_ok:null,
            fabric_type:null,
          },
      yarnDetailModelDefault:
          {
            id: null,
            to_who: null,
            order_id: this.fabricRequestId,
            order_fabric_id: null,
            fabric_yarn_type_id: [],
            fabric_yarn_types: [],
            is_submitted: false,
            is_editing: false,
            supplier_company_id: null,
            dispatch_location_id: null,
            amount_unit_id: null,
            price: null,
            yarn_type: null,
            flow_date: moment(),
            end_date: moment(),
            amount: null,
            total_amount: null,
            currency_unit_id: null,
            exchange_rate: 1,
            expiry_id: null,
            total_price: null,
            description: null
          },
      knitDetailModelDefault:
          {
            id: null,
            order_id: this.fabricRequestId,
            is_submitted: false,
            is_editing: false,
            supplier_company_id: null,
            fabric_type: null,
            fabric_knit_type_id: null,
            flow_date: moment(),
            end_date: moment(),
            dispatch_location_id: null,
            amount_unit_id: null,
            price: null,
            fein_pus: null,
            amount: null,
            total_amount: null,
            exchange_rate: 1,
            total_price: null,
            currency_unit_id: null,
            expiry_id: null,
            unit_price: null,
            description: null
          },
      paintDetailModelDefault:
          {
            id: null,
            order_id: this.fabricRequestId,
            is_submitted: false,
            is_editing: false,
            supplier_company_id: null,
            dispatch_location_id: null,
            fabric_process_ids: [],
            raw_flow_date: moment(),
            raw_end_date: moment(),
            painted_flow_date: moment(),
            painted_end_date: moment(),
            to_who: null,
            amount: null,
            total_amount: null,
            exchange_rate: 1,
            price: null,
            total_price: null,
            selectedProductProcesses: [],
            product_processes: [],
            currency_unit_id: null,
            expiry_id: null,
            description: null,
          },
      additionDetailModelDefault:
          {
            id: null,
            order_id: this.fabricRequestId,
            is_submitted: false,
            is_editing: false,
            supplier_company_id: null,
            fabric_process_ids: [],
            dispatch_location_id: null,
            raw_flow_date: moment(),
            raw_end_date: moment(),
            painted_flow_date: moment(),
            painted_end_date: moment(),
            fabric_type: null,
            amount: null,
            amount_unit_id: null,
            total_amount: null,
            exchange_rate: 1,
            selectedProductProcesses: [],
            product_processes: [],
            currency_unit_id: null,
            expiry_id: null,
            price: null,
            description: null
          },
      printDetailModelDefault:
          {
            id: null,
            order_id: this.fabricRequestId,
            is_submitted: false,
            is_editing: false,
            press_type_id: null,
            supplier_company_id: null,
            to_who: null,
            fabric_process_ids: [],
            fabric_knit_type_id: null,
            dispatch_location_id: null,
            raw_flow_date: moment(),
            raw_end_date: moment(),
            painted_flow_date: moment(),
            painted_end_date: moment(),
            amount: null,
            tolerance_amount: null,
            total_amount: null,
            exchange_rate: 1,
            total_price: null,
            selectedProductProcesses: [],
            product_processes: [],
            currency_unit_id: null,
            amount_unit_id: null,
            expiry_id: null,
            price: null,
            description: null,
          },
      rawFabricDetailModelDefault:
          {
            id: null,
            order_id: this.fabricRequestId,
            order_size_and_color_id: null,
            is_submitted: false,
            is_editing: false,
            supplier_company_id: null,
            fabric_type: null,
            knitting_type: null,
            flow_date: moment(),
            end_date: moment(),
            dispatch_location_id: null,
            to_who: null,
            fein_pus: null,
            amount: null,
            tolerance_amount: null,
            product_processes: [],
            currency_unit_id: null,
            expiry_id: null,
            total_price: null,
            exchange_rate: 1,
            unit_price: null,
            vat_rate_id: null,
            description: null,
          },

      configs: {},
      fabricALL: [],
      accessoryExecuteResult: [],
      to_show_fabric_request_ready_purchases: false,

      selectedResponsiblePersonForFabricRequest: null,
      isCreatedResponsiblePerson: false,
      continueAccessoryRequest: false,

      usersList: [],
      supplierCompanies: [],
      yarnTypes: [],
      unitOptions: [],
      extraProcessTypes: {},
      productProcess: {},
      pressTypeOptions: [],
      dispatchLocation: [],
      fabricKnitTypes: {},
      fabricProcessTypes: {},
      fabricRequests: [],
      currencyRateOptions: [],
      fabricRequestId: this.$route.params.fabricRequestId,
      accessoryRequestId: this.$route.params.accessoryRequestId,
      accessoryItemId: this.$route.params.itemId,

      currencyOptions: {},
      currencyOptionsRaw: [],
      expirationTypeOptions: {},
      fabricRequestForm: {
        isSubmitted: false,
        currency_id: null,
        hasExtraProcess: null,
        yarn: {
          description: null,
          showDescription: false,
          detail: [
            {
              id: null,
              pdf_link: null,
              supplier_company_id: null,
              fabric_yarn_type_id: [],
              fabric_yarn_types: [],
              flow_date: moment(),
              end_date: moment(),
              amount: null,
              amount_unit_id: null,
              price: null,
              dispatch_location_id: null,
              currency_unit_id: null,
              description: null,
              showDescription: false,
              expiry_id: null,
              total_price: 0,
              to_who: null,
            }
          ]
        },
        knitting: {
          description: null,
          showDescription: false,
          detail: [
            {
              supplier_company_id: null,
              fabric_knit_type_id: [],
              fein_pus: null,
              amount: null,
              amount_unit_id: null,
              flow_date: moment(),
              end_date: moment(),
              dispatch_location_id: null,
              price: null,
              currency_unit_id: null,
              description: null,
              showDescription: false,
              expiry_id: null,
              total_price: 0,
              to_who: null,
            }
          ]
        },
        paint: {
          description: null,
          showDescription: false,
          detail: [
            {
              supplier_company_id: null,
              amount: null,
              amount_unit_id: null,
              fabric_process_id: [],
              raw_flow_date: moment(),
              raw_end_date: moment(),
              painted_flow_date: moment(),
              painted_end_date: moment(),
              dispatch_location_id: null,
              price: null,
              currency_unit_id: null,
              description: null,
              showDescription: false,
              expiry_id: null,
              total_price: 0,
              to_who: null,
              product_processes: [],
            }
          ]
        },
        press: {
          description: null,
          showDescription: false,
          press_type_id: [],
          detail: [
            {
              press_type_id: null,
              supplier_company_id: null,
              amount: null,
              amount_unit_id: null,
              fabric_process_id: [],
              raw_flow_date: moment(),
              raw_end_date: moment(),
              painted_flow_date: moment(),
              painted_end_date: moment(),
              dispatch_location_id: null,
              price: null,
              currency_unit_id: null,
              description: null,
              showDescription: false,
              expiry_id: null,
              total_price: 0,
              to_who: null,
            }
          ]
        },
        rawFabric: {
          description: null,
          showDescription: false,
          detail: [
            {
              supplier_company_id: null,
              fabric_knit_type_id: [],
              fein_pus: null,
              amount: null,
              amount_unit_id: null,
              flow_date: moment(),
              end_date: moment(),
              dispatch_location_id: null,
              price: null,
              currency_unit_id: null,
              description: null,
              showDescription: false,
              expiry_id: null,
              total_price: 0,
              to_who: null,
            }
          ]
        },
        extraProcess: {
          description: null,
          showDescription: null,
          process_id: [],
          detail: {}
        },
        readMade: {
          description: null,
          showDescription: false,
          detail: [
            {
              supplier_company_id: null,
              amount: null,
              amount_unit_id: null,
              fabric_process_id: [],
              raw_flow_date: moment(),
              raw_end_date: moment(),
              painted_flow_date: moment(),
              painted_end_date: moment(),
              dispatch_location_id: null,
              price: null,
              currency_unit_id: null,
              description: null,
              showDescription: false,
              expiry_id: null,
              total_price: 0,
              to_who: null,
            }
          ]
        },
      },
      accessoryRequest: {},
      accessoryRequestForm: {
        isSubmitted: false,
        showDescription: false,
        description: null,
        currency_id: null,
        detail: [
          {
            supplier_company_id: null,
            dispatch_location_id: null,
            deadline: moment(),
            amount: null,
            amount_unit_id: null,
            price: null,
            currency_unit_id: null,
            description: null,
            showDescription: false,
            start_date: moment(),
            end_date: moment(),
            to_who: null,
            expiry_id: null,
            total_price: 0,
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations({
      setLoading: SET_LOADING,
    }),
    repeatFabricRequest() {
      let self = this;
      this.$store.dispatch(CREATE_ITEM, {
        url: `api/fabric-requests/${this.fabricRequestId}/copy`,
      }).then(result => {
        if (result.status) {
          self.sweetAlertSuccess(self.$t('general.successfully_created')).then(_ => {
            self.$router.replace({name: 'sample.task.index'}).catch(err => {
            })
          })
        }
      })
    },
    createResponsiblePersonForFabricRequest() {
      // TODO:send request to create responsible person for current sample
      this.isCreatedResponsiblePerson = true;

      if (!this.isValidToSubmitFabricRequest) {
        this.sweetAlertWarning('sample.please_fill_all_the_required_fields');
      }
      //TODO: send data to backend base on fabric type
      let self = this;
      this.setLoading(true)
      this.$store.dispatch(PATCH, {
        url: `api/fabric-requests/${this.fabricRequestId}/brand-manager`,
        contents: {
          brand_manager_id: this.selectedResponsiblePersonForFabricRequest,
        },
        showLoading: false,
      }).then(response => {
        if (response.status) {
          self.selected_brand_manager_id = this.selectedResponsiblePersonForFabricRequest;
          self.sweetAlertSuccess('general.successfully_updated');
        } else {
          self.sweetAlertError('general.internal_error');
        }
      })
      this.sweetAlertSuccess(this.$t('general.successfully_created'))
    },
    convertDMYDateByGivenList(value, key) {
      for (let i = 0; i < value.length; i++) {
        for (let j = 0; j < key.length; j++) {
          value[i][key[j]] = moment(value[i][key[j]]).format(LARAVEL_DATE_FORMAT);
        }
        if ('price' in value[i] && 'amount' in value[i]) {
          value[i].total_price = Number(value[i].price) * Number(value[i].amount);
        }
      }
      return value;
    },
    fixedReadyMadePressExtraAndPaintFormForSubmission(value) {
      for (let i = 0; i < value.length; i++) {
        if ('fabric_process_id' in value[i]) {
          value[i]['fabric_processes'] = [];
          for (let j = 0; j < value[i].fabric_process_id.length; j++) {
            value[i]['fabric_processes'].push({
              fabric_process_id: value[i].fabric_process_id[j]
            });
          }
        } else {
          break;
        }
      }
      return this.convertDMYDateByGivenList(value, ['raw_flow_date', 'raw_end_date', 'painted_flow_date', 'painted_end_date'])
    },

    fixedAccessoryFormForSubmission(value) {
      return this.convertDMYDateByGivenList(value, ['deadline', 'start_date', 'end_date'])
    },
    submitAccessoryRequest() {
      if (!this.isValidToSubmitAccessoryRequest) {
        this.sweetAlertWarning('sample.please_fill_all_the_required_fields');
      }
      // TODO:send data to backend base on accessory type
      let self = this;
      this.$store.dispatch(CREATE_ITEM, {
        url: `api/accessory-requests/${this.$route.params.accessoryRequestId}/execute`,
        contents: {
          accessory_request_informations: self.fixedAccessoryFormForSubmission(self.accessoryRequestForm.detail)
        },
        acceptPromise: true,
      }).then((result) => {
        if (result.status) {
          self.accessoryExecuteResult = result.data
          self.sweetAlertSuccess(this.$t('general.successfully_created'))
          self.accessoryRequestForm.isSubmitted = true;
        }
      })
    },
    confirmRequestFabric() {
      this.$refs['select-responsible-person-for-fabric-request'].show();
    },
    confirmRequestAccessory() {
      this.continueAccessoryRequest = true;
    },
    onResponsiblePersonSelected() {
      this.selected_brand_manager_id = null;
      this.$refs['select-responsible-person-for-fabric-request'].hide();
    },
    isYarnValid() {
      for (let i = 0; i < this.fabricRequestForm.yarn.detail.length; i++) {
        let current = this.fabricRequestForm.yarn.detail[i];
        if (current.supplier_company_id === null) return false;
        if (current.fabric_yarn_type_id === null) return false;
        if (current.amount === null || current.amount === '') return false;
        if (Number(current.amount_unit_id) === 0) return false;
        if (Number(current.price) === 0) return false;
        if (current.dispatch_location_id === null) return false;
        if (current.currency_unit_id === null || current.currency_unit_id === 'null') return false;
      }
      return true;
    },
    isKnittingValid() {
      for (let i = 0; i < this.fabricRequestForm.knitting.detail.length; i++) {
        let current = this.fabricRequestForm.knitting.detail[i];
        if (current.supplier_company_id === null) return false;
        if (current.fabric_knit_type_id.length === 0) return false;
        if (current.fein_pus === '' || current.fein_pus === null) return false;
        if (current.dispatch_location_id === null) return false;
        if (Number(current.price) === 0) return false;
        if (current.currency_unit_id === null || current.currency_unit_id === 'null') return false;
      }
      return true;
    },
    isPaintValid() {
      for (let i = 0; i < this.fabricRequestForm.paint.detail.length; i++) {
        let current = this.fabricRequestForm.paint.detail[i];
        if (Number(current.supplier_company_id) === null) return false;
        if (Number(current.amount) === 0) return false;
        if (Number(current.amount_unit_id) === 0) return false;
        if (current.fabric_process_id.length === 0) return false;
        if (current.dispatch_location_id === null) return false;
        if (Number(current.price) === 0) return false;
        if (current.currency_unit_id === null || current.currency_unit_id === 'null') return false;
        if (current.product_processes.length === 0) return false;
      }
      return true;
    },
    isPressValid() {
      for (let i = 0; i < this.fabricRequestForm.press.detail.length; i++) {
        let current = this.fabricRequestForm.press.detail[i];
        if (current.supplier_company_id === null) return false;
        if (current.press_type_id === null) return false;
        if (Number(current.amount) === 0) return false;
        if (Number(current.amount_unit_id) === 0) return false;
        if (current.fabric_process_id.length === 0) return false;
        if (current.dispatch_location_id === null) return false;
        if (Number(current.price) === 0) return false;
        if (current.currency_unit_id === null || current.currency_unit_id === 'null') return false;
      }
      return true;
    },
    isRawFabricValid() {
      for (let i = 0; i < this.fabricRequestForm.rawFabric.detail.length; i++) {
        let current = this.fabricRequestForm.rawFabric.detail[i];
        if (current.supplier_company_id === null) return false;
        if (current.fabric_knit_type_id.length === 0) return false;
        if (current.fein_pus === '' || current.fein_pus === null) return false;
        if (Number(current.amount) === 0) return false;
        if (Number(current.amount_unit_id) === 0) return false;
        if (current.dispatch_location_id === null) return false;
        if (Number(current.price) === 0) return false;
        if (current.currency_unit_id === null || current.currency_unit_id === 'null') return false;
      }
      return true;
    },
    areExtraProcessesValid() {
      if (!this.fabricRequestForm.hasExtraProcess) return true;
      for (let key in this.fabricRequestForm.extraProcess.detail) {
        let temp = this.fabricRequestForm.extraProcess.detail[key];
        for (let i = 0; i < temp.detail.length; i++) {
          let current = temp.detail[i];
          if (current.supplier_company_id === null) return false;
          if (Number(current.amount) === 0) return false;
          if (Number(current.amount_unit_id) === 0) return false;
          if (current.fabric_process_id.length === 0) return false;
          if (current.dispatch_location_id === null) return false;
          if (Number(current.price) === 0) return false;
          if (current.currency_unit_id === null || current.currency_unit_id === 'null') return false;
        }
      }
      return true;
    },
    isReadMadeValid() {
      for (let i = 0; i < this.fabricRequestForm.readMade.detail.length; i++) {
        let current = this.fabricRequestForm.readMade.detail[i];
        if (current.supplier_company_id === null) return false;
        if (Number(current.amount) === 0) return false;
        if (Number(current.amount_unit_id) === 0) return false;
        if (current.fabric_process_id.length === 0) return false;
        if (current.dispatch_location_id === null) return false;
        if (Number(current.price) === 0) return false;
        if (current.currency_unit_id === null || current.currency_unit_id === 'null') return false;
      }
      return true;
    },
    changeSelectedSubSource(value) {
      if (+value.id === 2 && this.yarnDetailModel[0].is_submitted) {
        this.selectedSubSourcingType = value.id;
      }

      if (+value.id === 3 && (+this.fabricProcessingType === 1 && this.knitDetailModel[0].is_submitted) ||
          (+this.fabricProcessingType === 3 && this.rawFabricDetailModel[0].is_submitted)) {
        this.selectedSubSourcingType = value.id;
      }

      if (+value.id === 5 && (+this.fabricProcessingType === 2 && this.knitDetailModel[0].is_submitted) ||
          (+this.fabricProcessingType === 4 && this.rawFabricDetailModel[0].is_submitted)) {
        this.selectedSubSourcingType = value.id;
      }

      if (+value.id === 6 && ((+this.fabricProcessingType === 1 || +this.fabricProcessingType === 3) && this.paintDetailModel[0].is_submitted) ||
          ((+this.fabricProcessingType === 2 || +this.fabricProcessingType === 4) && this.printDetailModel[0].is_submitted)) {
        this.selectedSubSourcingType = value.id;
      }

      if (+value.id < +this.selectedSubSourcingType) {
        this.selectedSubSourcingType = value.id;
      }
    },
    onFabricAdditionValueChange(payload) {
      this.additionDetailModel = this.additionDetailModel.map(detail => {
        detail.fabric_addition_id = payload.newValue;
        return detail;
      })
    },
    changeFabricPrintTypeId(payload) {
      this.selectedPrintTypeId = payload;
    },
    getRequests() {
      if (!this.orderFabricId) return false;

      let fabricProcessingType = Number(this.fabricProcessingType) ?? 0;

      if (+this.fabricSourcingType === 2) {
        fabricProcessingType = 5
      }

      this.fabricProcessingType = fabricProcessingType;

      if (!fabricProcessingType) return false;
      this.getIncomingFabricAmount();

      let self = this;
      let sampleId = this.$route.params.id;
      let url = 'api/sample/' + sampleId + '/fabric-request/' + this.fabricRequestId + '/fabric-supply-type/' + fabricProcessingType;
      this.sweetAlertLoading();

      this.$store.dispatch(GET_ITEMS, {
        url: url,
        acceptPromise: true,
      }).then((result) => {
        if (result.status) {
          let data = result.data;
          let tmpItems = [];
          let tmpRequests = [];

          self.yarnDetailModel = [{}];
          self.readyMadeDetailModel = [{}];
          self.knitDetailModel = [{}];
          self.paintDetailModel = [{}];
          self.additionDetailModel = [{}];
          self.printDetailModel = [{}];
          self.rawFabricDetailModel = [{}];

          if (fabricProcessingType === 1 || fabricProcessingType === 2) {
            tmpRequests = data.yarn_requests;
            tmpItems = [];
            tmpRequests.forEach(item => {
              item.fabric_yarn_types = _.map(item.fabric_request_fabric_yarn_types, function (subItem) {
                return {
                  "fabric_yarn_type_id": subItem.fabric_yarn_type_id,
                  "amount": subItem.amount
                }
              });

              item.fabric_yarn_type_id = _.map(item.fabric_request_fabric_yarn_types, function (subItem) {
                return subItem.fabric_yarn_type_id.toString();
              });
              item.is_submitted = true;
              item.is_editing = false;

              tmpItems.push(item);
            })

            if (!tmpItems.length) {
              tmpItems.push(_.cloneDeepWith(this.yarnDetailModelDefault));
            }

            self.yarnDetailModel = tmpItems;
          }

          if (fabricProcessingType === 1 || fabricProcessingType === 2) {
            // Getting knit requests
            tmpRequests = data.knit_requests;
            tmpItems = [];
            tmpRequests.forEach(item => {
              item.is_submitted = true;
              item.is_editing = false;
              tmpItems.push(item);
            })

            if (!tmpItems.length) {
              tmpItems.push(_.cloneDeepWith(this.knitDetailModelDefault));
            }

            self.knitDetailModel = tmpItems;
          }
          if (fabricProcessingType === 1 || fabricProcessingType === 3) {
            // Getting paint requests
            tmpRequests = data.paint_requests;
            tmpItems = [];
            tmpRequests.forEach(item => {
              item.dispatch_location_ids = _.map(item.dispatch_locations, function (subItem) {
                return subItem.dispatch_location_id.toString();
              });
              item.is_submitted = true;
              item.is_editing = false;

              item.fabric_process_ids = item.fabric_processes.map(process => {
                process.id = process.fabric_process_id.toString();
                return process.fabric_process_id.toString();
              });

              item.selectedProductProcesses = item.product_processes.map(process => {
                process.id = process.product_process_id.toString();
                return process.product_process_id.toString();
              });

              tmpItems.push(item);
            })

            if (!tmpItems.length) {
              tmpItems.push(_.cloneDeepWith(this.paintDetailModelDefault));
            }

            self.paintDetailModel = tmpItems;
          }

          if (fabricProcessingType === 2 || fabricProcessingType === 4) {
            // Getting print requests
            tmpRequests = data.print_requests;
            tmpItems = [];
            tmpRequests.forEach(item => {
              item.dispatch_location_ids = _.map(item.dispatch_locations, function (subItem) {
                return subItem.dispatch_location_id.toString();
              });
              item.is_submitted = true;
              item.is_editing = false;

              self.selectedPrintTypeId = item.press_type_id;

              item.fabric_process_ids = item.fabric_processes.map(process => {
                process.id = process.fabric_process_id.toString();
                return process.fabric_process_id.toString();
              });

              tmpItems.push(item);
            })

            if (!tmpItems.length) {
              tmpItems.push(_.cloneDeepWith(this.printDetailModelDefault));
            }

            self.printDetailModel = tmpItems;
          }

          if (fabricProcessingType === 3 || fabricProcessingType === 4) {
            // Getting print requests
            tmpRequests = data.raw_requests;
            tmpItems = [];
            tmpRequests.forEach(item => {
              item.dispatch_location_ids = _.map(item.dispatch_locations, function (subItem) {
                return subItem.dispatch_location_id.toString();
              });
              item.is_submitted = true;
              item.is_editing = false;
              tmpItems.push(item);
            })

            if (!tmpItems.length) {
              tmpItems.push(_.cloneDeepWith(this.rawFabricDetailModelDefault));
            }

            self.rawFabricDetailModel = tmpItems;
          }

          if (fabricProcessingType === 5) {
            // Getting print requests
            tmpRequests = data.ready_requests;
            tmpItems = [];
            tmpRequests.forEach(item => {

              item.is_submitted = true;
              item.is_editing = false;

              item.fabric_process_ids = item.fabric_processes.map(process => {
                process.id = process.fabric_process_id.toString();
                return process.fabric_process_id.toString();
              });

              tmpItems.push(item);
            })

            if (!tmpItems.length) {
              tmpItems.push(_.cloneDeepWith(this.readyMadeDetailModelDefault));
            }

            self.readyMadeDetailModel = tmpItems;
          }

          if (fabricProcessingType !== 5) {
            // Getting print requests
            tmpRequests = data.addition_requests;
            tmpItems = [];
            tmpRequests.forEach(item => {
              item.dispatch_location_ids = _.map(item.dispatch_locations, function (subItem) {
                return subItem.dispatch_location_id.toString();
              });
              item.is_submitted = true;
              item.is_editing = false;

              item.fabric_process_ids = item.fabric_processes.map(process => {
                process.id = process.fabric_process_id.toString();
                return process.fabric_process_id.toString();
              });

              tmpItems.push(item);
            })

            if (!tmpItems.length) {
              tmpItems.push(_.cloneDeepWith(this.additionDetailModelDefault));
            }

            self.additionDetailModel = tmpItems;
          }
        }
        this.sweetAlertClose();
      })
    },
    getIncomingFabricAmount() {
      if (!this.orderFabricId) return false;
      let self = this;
      self.netIncomingFabricAmount = 0;
      return false;
      this.sweetAlertLoading();
      let url = 'api/order/' + this.order.id + '/order-fabric/' + this.orderFabricId + '/amount';
      this.$store.dispatch(GET_ITEMS, {
        url: url,
        acceptPromise: true,
      }).then((result) => {
        this.sweetAlertClose();

        if (result.status) {
          self.netIncomingFabricAmount = result.data;
        }
      })
    },
    getFabricRequestData() {
      let promises = [], self = this;

      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/users/brand-managers',
        acceptPromise: true,
        showLoading: false,
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/supplier-companies',
        acceptPromise: true,
        showLoading: false,
        filters: {all: true}
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/fabric-yarn-types',
        acceptPromise: true,
        showLoading: false
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/units',
        acceptPromise: true,
        showLoading: false
      }));

      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/supplier-companies',
        acceptPromise: true,
        showLoading: false
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/currency',
        acceptPromise: true,
        showLoading: false
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/fabric-process',
        acceptPromise: true,
        showLoading: false
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/fabric-knit-types',
        acceptPromise: true,
        showLoading: false
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/fabric-additions',
        acceptPromise: true,
        showLoading: false
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/product-process',
        acceptPromise: true,
        showLoading: false
      }));

      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/expiries'
      }));

      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/press-types',
        filters: {
          all: true
        }
      }));

      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/currency-rate'
      }));

      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/fabric-requests/' + this.fabricRequestId,
        filters: {}
      }));

      Promise.all(promises).then((results) => {
        if (results[0].status) {
          self.usersList = results[0].data;
        }
        if (results[1].status) {
          self.supplierCompanies = self.convertArrayToObjectByKey(results[1].data, 'id', 'name');
        }
        if (results[2].status) {
          self.yarnTypes = self.convertArrayToObjectByKey(results[2].data, 'id');
        }
        if (results[3].status) {
          self.unitOptions = self.convertArrayToObjectByKey(results[3].data, 'id', 'code');
        }

        if (results[4].status) {
          self.dispatchLocation = self.convertArrayToObjectByKey(results[4].data, 'id', 'name');
        }

        if (results[5].status) {
          self.currencyOptionsRaw = results[5].data;
          self.currencyOptions = {...self.convertArrayToObjectByKey(results[5].data, 'id', 'code')}
        }
        if (results[6].status) {
          self.fabricProcessTypes = self.convertArrayToObjectByKey(results[6].data, 'id', 'translations.0.name');
        }
        if (results[7].status) {
          self.fabricKnitTypes = self.convertArrayToObjectByKey(results[7].data, 'id', 'translations.0.name');
        }
        if (results[8].status) {
          self.extraProcessTypes = self.convertArrayToObjectByKey(results[8].data, 'id', 'translations.0.name');
        }
        if (results[9].status) {
          self.productProcess = self.convertArrayToObjectByKey(results[9].data, 'id', 'translations.0.name');
        }
        if (results[10].status) {
          self.expirationTypeOptions = self.convertArrayToObjectByKey(results[10].data, 'id');
        }
        if (results[11].status) {
          self.pressTypeOptions = self.convertArrayToObjectByKey(results[11].data, 'id', 'translations.0.name');
        }
        if (results[12].status) {
          self.currencyRateOptions = results[12].data.data;
        }
        if (results[13].status) {
          let data = results[13].data;
          self.selected_brand_manager_id = data.brand_manager_id;
          self.selectedResponsiblePersonForFabricRequest = data.brand_manager_id;
        }
      }).catch((error) => {
        console.error({error});
      })
    },
    getAccessoryRequestData() {
      let promises = [], self = this;
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/supplier-companies',
        acceptPromise: true,
        showLoading: false,
        filters: {all: true}
      }));

      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/units',
        acceptPromise: true,
        showLoading: false
      }));

      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/currency',
        acceptPromise: true,
        showLoading: false
      }));

      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/expiries'
      }));

      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/currency-rate'
      }));

      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/accessory-requests/' + this.accessoryRequestId,
        filters: {}
      }));

      Promise.all(promises).then((results) => {
        if (results[0].status) {
          self.supplierCompanies = self.convertArrayToObjectByKey(results[0].data, 'id', 'name');
          self.dispatchLocation = self.convertArrayToObjectByKey(results[0].data, 'id', 'name');
        }

        if (results[1].status) {
          self.unitOptions = self.convertArrayToObjectByKey(results[1].data, 'id', 'logo_name');
        }

        if (results[2].status) {
          self.currencyOptionsRaw = results[2].data;
          self.currencyOptions = {...self.convertArrayToObjectByKey(results[2].data, 'id', 'code')}
        }

        if (results[3].status) {
          self.expirationTypeOptions = self.convertArrayToObjectByKey(results[3].data, 'id');
        }

        if (results[4].status) {
          self.currencyRateOptions = results[4].data.data;
        }
        if (results[5].status) {
          let data = results[5].data;
          if (data.is_requested) {
            self.continueAccessoryRequest = true;
            let tmpItems = [];
            let tmpRequests = [];

            self.accessoryDetailModel = [{}];

            // Getting accessory requests
            tmpRequests = data.accessory_request_informations;
            tmpItems = [];
            tmpRequests.forEach(item => {
              item.is_submitted = true;
              item.is_editing = false;
              tmpItems.push(item);
            })

            if (!tmpItems.length) {
              tmpItems.push(_.cloneDeepWith(this.readyMadeDetailModelDefault));
            }

            self.accessoryDetailModel = tmpItems;
          } else {
            let sample = this.sample.accessory_sample.find(item => +item.id === +this.accessoryItemId);
            this.accessoryDetailModel[0].amount_unit_id = Number(sample.unit_id)
            this.accessoryDetailModel[0].amount = Number(sample.consumption)
          }
        }
      }).catch((error) => {
        console.error({error});
      })
    }
  },
  mounted() {
    this.yarnDetailModel = [_.cloneDeepWith(this.yarnDetailModelDefault)];
    this.knitDetailModel = [_.cloneDeepWith(this.knitDetailModelDefault)];
    this.printDetailModel = [_.cloneDeepWith(this.printDetailModelDefault)];
    this.paintDetailModel = [_.cloneDeepWith(this.paintDetailModelDefault)];
    this.rawFabricDetailModel = [_.cloneDeepWith(this.rawFabricDetailModelDefault)];
    this.additionDetailModel = [_.cloneDeepWith(this.additionDetailModelDefault)];
    this.readyMadeDetailModel = [_.cloneDeepWith(this.readyMadeDetailModelDefault)];
    this.accessoryDetailModel = [_.cloneDeepWith(this.accessoryDetailModelDefault)];

    this.fabricSourcingType = +this.fabric_supply_type_id === 5 ? 2 : 1;
    this.fabricProcessingType = +this.fabric_supply_type_id === 5 ? null : +this.fabric_supply_type_id;

    this.orderFabricId = this.$route.params.fabricRequestId;
    let requestType = this.$route.params.requestType;
    let settings = _.first(this.settings);
    if (settings) {
      this.configs = settings.fabric_request;
    }

    if (+requestType === 1) {
      this.getFabricRequestData();
    } else if (+requestType === 2) {
      this.getAccessoryRequestData();
    }
    if(this.$route.query.qr_type=="fabric-request-ready"){
      this.fabricSourcingType=2;
      this.orderFabricId=this.$route.query.data.fabric_request_id;
      this.getRequests();
    }

    if(this.$route.query.qr_type=="request-raw-fabrics"){
      let data=this.$route.query.data;
      this.fabricSourcingType=1;
      this.fabricProcessingType=data.fabric_supply_type_id;
      this.orderFabricId=data.fabric_request_id
      this.selectedSubSourcingType=4;
      this.getRequests();
    }
    if(this.$route.query.qr_type=="request-prints"){
      let data=this.$route.query.data;
      this.fabricSourcingType=1;
      this.fabricProcessingType=data.fabric_supply_type_id;
      this.orderFabricId=data.fabric_request_id
      this.selectedSubSourcingType=5;
      this.getRequests();
    }

    if(this.$route.query.qr_type=="request-paints"){
      let data=this.$route.query.data;
      this.fabricSourcingType=1;
      this.fabricProcessingType=data.fabric_supply_type_id;
      this.orderFabricId=data.fabric_request_id
      this.selectedSubSourcingType=3;
      this.getRequests();
    }

    if(this.$route.query.qr_type=="request-knits"){
      let data=this.$route.query.data;
      this.fabricSourcingType=1;
      this.fabricProcessingType=data.fabric_supply_type_id;
      this.orderFabricId=data.fabric_request_id
      this.selectedSubSourcingType=2;
      this.getRequests();
    }
    if(this.$route.query.qr_type=="request-yarns"){
      let data=this.$route.query.data;
      this.fabricSourcingType=1;
      this.fabricProcessingType=data.fabric_supply_type_id;
      this.orderFabricId=data.fabric_request_id
      this.selectedSubSourcingType=1;
      this.getRequests();
    }
    if(this.$route.query.qr_type=="addition-request"){
      let data=this.$route.query.data;
      this.fabricSourcingType=1;
      this.fabricProcessingType=data.fabric_supply_type_id;
      this.orderFabricId=data.fabric_request_id
      this.selectedSubSourcingType=6;
      this.getRequests();
    }
  },
  watch: {
    fabricProcessingType(newValue, oldValue) {
      let isIncluded = false, firstAvailable = null;

      for (let key in this.outSourcingTypes) {
        const current = this.outSourcingTypes[key]
        if (firstAvailable === null && current.value.includes(+newValue)) {
          firstAvailable = current.id;
        }
        if (current.value.includes(+newValue) && +this.selectedSubSourcingType === current.id) {
          isIncluded = true;
          break;
        }
      }
      if (!isIncluded) {
        this.selectedSubSourcingType = firstAvailable;
      }

      if (!newValue && newValue !== 0) {
        this.fabricProcessingType = 0;
      }
    }
  },
}
</script>

<style scoped>

</style>
